import Keycloak from "keycloak-js";
import { getEnvValue } from "./get-env";

const keycloak = new Keycloak({
  url: getEnvValue("REACT_APP_KEYCLOAK_URL"),
  realm: getEnvValue("REACT_APP_KEYCLOAK_REALM") || "",
  clientId: getEnvValue("REACT_APP_KEYCLOAK_CLIENT_ID") || "",
});

export default keycloak;
